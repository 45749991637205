function vectiAppApiUrl() {
  if (import.meta.env.VECTI_APP_API_URL) {
    // console.log("vectiAppApiUrl", import.meta.env.VECTI_APP_API_URL);
    return import.meta.env.VECTI_APP_API_URL;
  }
  throw new Error("VECTI_APP_API_URL is not set");
}

function vectiAppAuth0Domain() {
  return import.meta.env.VECTI_APP_AUTH0_DOMAIN;
}

function vectiAppAuth0ClientId() {
  return import.meta.env.VECTI_APP_AUTH0_CLIENT_ID;
}

function vectiAppAuth0RedirectUrl() {
  return import.meta.env.VECTI_APP_AUTH0_REDIRECT_URL;
}

function vectiAppIsStageProd() {
  return import.meta.env.VECTI_APP_STAGE == 'prd';
}

export {
  vectiAppApiUrl,
  vectiAppAuth0Domain,
  vectiAppAuth0ClientId,
  vectiAppAuth0RedirectUrl,
  vectiAppIsStageProd
};
