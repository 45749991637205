import { APIRepository } from "~/context/Shared/infrastructure/APIRepository";
import { InvoiceDataRepository } from "../domain/InvoiceDataRepository";
import { vectiAppApiUrl } from "~/context/Shared/infrastructure/Config";
import { InvoiceRetrieveData } from "../domain/InvoiceRetrieveData";

export function createApiInvoiceDataRepository(): InvoiceDataRepository {
  return new ApiInvoiceDataRepository();
}

export class ApiInvoiceDataRepository extends APIRepository implements InvoiceDataRepository {
  constructor() {
    super(vectiAppApiUrl());
  }

  getInvoicesRetrievedByCompanyAndDate(companyUid: string, dateBegin: string, dateEnd: string): Promise<InvoiceRetrieveData[]> {
    console.log("getInvoicesRetrievedByCompanyAndDate companyUid: " + companyUid);
    //transforma el dateBegin en un formato yyyymmdd. El dateBegin viene como un string de date ISO
    //const dateBeginFormatted = new Date(dateBegin).toISOString().split("T")[0].replace(/-/g, "");
    console.log("dateBegin: " + JSON.stringify(dateBegin));
    console.log("dateEnd: " + JSON.stringify(dateEnd));

    const dateBeginFormatted = formatDate(dateBegin);
    const dateEndFormatted = formatDate(dateEnd);

    console.log("dateBeginFormatted: " + JSON.stringify(dateBeginFormatted));
    console.log("dateEndFormatted: " + JSON.stringify(dateEndFormatted));

    return this.doGet<InvoiceRetrieveData[]>("/api/invoicesdata/loaded/company/" + companyUid.toString() + "/begin/" + dateBeginFormatted + "/end/ " + dateEndFormatted);
  }


}

function formatDate(dateString: string): string {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses van de 0 a 11
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}${month}${day}`;
}
